import { render, staticRenderFns } from "./polls.vue?vue&type=template&id=6c3555a8&"
import script from "./polls.vue?vue&type=script&lang=js&"
export * from "./polls.vue?vue&type=script&lang=js&"
import style0 from "./polls.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!C:/proyectos/rosalinda-store/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib'
import { VBtn } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VCardActions } from 'vuetify/lib'
import { VCardText } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VDataTable } from 'vuetify/lib'
import { VDatePicker } from 'vuetify/lib'
import { VDialog } from 'vuetify/lib'
import { VDivider } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VList } from 'vuetify/lib'
import { VListTile } from 'vuetify/lib'
import { VListTileAction } from 'vuetify/lib'
import { VListTileTitle } from 'vuetify/lib'
import { VMenu } from 'vuetify/lib'
import { VProgressLinear } from 'vuetify/lib'
import { VSpacer } from 'vuetify/lib'
import { VSubheader } from 'vuetify/lib'
import { VSwitch } from 'vuetify/lib'
import { VTextField } from 'vuetify/lib'
import { VTextarea } from 'vuetify/lib'
installComponents(component, {VAlert,VBtn,VCard,VCardActions,VCardText,VContainer,VDataTable,VDatePicker,VDialog,VDivider,VFlex,VIcon,VLayout,VList,VListTile,VListTileAction,VListTileTitle,VMenu,VProgressLinear,VSpacer,VSubheader,VSwitch,VTextField,VTextarea})
